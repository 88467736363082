/**
 * MAKE SURE GUEST IMAGES ARE PROPERLY SIZED TO 100x100 px FOR PERFORMANCE!
 */
import asiaInsuretech from "../images/press-media/guest/asia_insurtech.png"
import engageYourTribe from "../images/press-media/guest/engage_your_tribe.png"
import fnoInsuretech from "../images/press-media/guest/fno_insuretech.png"
import futureTrends from "../images/press-media/guest/future_trends_insurance.png"
import iir from "../images/press-media/guest/iir.png"
import insuretch from "../images/press-media/guest/insuretech.png"
import livLouder from "../images/press-media/guest/liv_louder.png"
import insuretechDenver from "../images/press-media/guest/insuretech_denver.png"
import techBlogWriter from "../images/press-media/guest/tech_blog_writer.png"

export const freshlySlicedPodcasts = [
  {
    title: "Automated Claims Settlement",
    itunes:
      "https://podcasts.apple.com/us/podcast/automated-claims-settlement/id1467798530?i=1000519416853",
    spotify: "https://open.spotify.com/episode/5ROGblIDzLZZ6GU4zZKaoW",
    soundcloud:
      "https://soundcloud.com/freshly_sliced/automated-claims-settlement",
    duration: "22 min.",
    details:
      "Guests Luc Lamadeleine and Adam Quinn-Taylor of Duuo, and Andy Clapson and Noel Dawe of Slice Labs chat through Slice's game changing automated claims settlement capabilities. Hosted by Philippe Lafrienere of Slice. Duuo and Slice worked together on developing the automated claims settlement, which is live in Duuo's Rent-my-Stuff product.",
  },
  {
    title: "Connected Mobility",
    itunes:
      "https://podcasts.apple.com/us/podcast/connected-mobility/id1467798530",
    spotify: "https://open.spotify.com/episode/6yRRgYqVZ1rsP9iXl12sic",
    soundcloud: "https://soundcloud.com/freshly_sliced/connected-mobility",
    duration: "28 min.",
    details:
      "In this episode, Philippe Lafreniere is joined by Tsuyoshi Nakashima of Denso and Michael Bodlée, mobility advisor and consultant. They discuss connected mobility, and how data is the future of mobility, yet the customer experience is the focus.",
  },
  {
    title: "The Evolving Distribution Landscape",
    itunes:
      "https://podcasts.apple.com/us/podcast/freshly-sliced/id1467798530#episodeGuid=tag%3Asoundcloud%2C2010%3Atracks%2F907189531",
    spotify: "https://open.spotify.com/episode/5ZyjcupdXT2wEXjsHIcO0k",
    soundcloud:
      "https://soundcloud.com/freshly_sliced/the-evolving-distribution-landscape",
    duration: "28 min.",
    details:
      "In this edition we chat about the changing landscape of Insurance Distribution, how to go about validating a new hypothesis with an MVP, continuously testing and learning with data, and the impact of the last 6 months has had on all of the above. With guests: Shannon Varney, Partner at McKinsey and Pietro Carnevale, CEO of HITS, the innovation arm of Generali and hosted by Philippe Lafreniere, CGO of Slice Labs.",
  },
  {
    title: "The Way Forward to Instant Insurance Payments",
    itunes:
      "https://podcasts.apple.com/us/podcast/the-way-forward-to-instant-insurance-payments/id1467798530?i=1000476012500",
    spotify: "https://open.spotify.com/episode/3mj4DcM2HH9fs1c3Uet3I9",
    soundcloud:
      "https://soundcloud.com/freshly_sliced/freshly-sliced-episode-10-the-way-forward-to-instant-insurance-payments",
    duration: "21 min.",
    details:
      "Customers expect on-demand, seamless, intuitive and simple experiences - whether buying groceries, watching a show, or paying their bills. Buying insurance is one area that is often fraught with friction, especially when it comes to claims processes and payments. Traditional home and automobile claims can take between 15-30+ days to settle the claim before it is even sent for payment, which is often still a paper check, sent by mail. Even with direct deposit, insurance claims payments that take days and sometimes weeks to process no longer have the innovative edge they once had. Data scientist, Andy Clapson and Metod Topolnik, CEO and Founder of Simplepin walk us through laying the foundation to delivering the same on-demand customer experiences in insurance claims and payments via the use of AI, small data and behavioral science.",
  },
  {
    title: "2020 SMB Cybersecurity Trends & Awareness",
    itunes:
      "https://podcasts.apple.com/us/podcast/2020-smb-cybersecurity-trends-awareness/id1467798530?i=1000465973570",
    spotify:
      "https://open.spotify.com/episode/5vdzSTsEMHtasyUrlYtLmr?si=ISthAg0ATd-uD2w-TSnBwA",
    soundcloud:
      "https://soundcloud.com/freshly_sliced/2020-smb-cybersecurity-security-trends-awareness",
    duration: "24 min.",
    details:
      "On our first episode of 2020 we discuss trends & awareness in cybersecurity. We are joined by Chris Hallum, Senior Product Manager at Microsoft. Daniel Eliot, Director of Education & Strategic Initiatives at National Cyber Security Alliance, and John Coletti, Chief Underwriting Officer Cyber at AXA XL. Hosted by Head of SMB at Slice, Jocelyn Getson. Enjoy!",
  },
  {
    title: "Looking Back At 2019 & Looking Forward To 2020 In InsurTech",
    itunes:
      "https://podcasts.apple.com/us/podcast/looking-back-at-2019-looking-forward-to-2020-in-insurtech/id1467798530?i=1000460265307",
    spotify:
      "https://open.spotify.com/episode/1GSsLODqTmk87jjLZxQlFx?si=UJXW-WvhQHG5To1QwDcwVA",
    soundcloud:
      "https://m.soundcloud.com/freshly_sliced/looking-back-at-2019-looking-forward-at-2020-in-insurtech",
    duration: "26 min.",
    details:
      "On the final episode of our 2019 Freshly Sliced podcast season, we discuss Insurtech trends and shifts in 2019, and visit what 2020 could look like. This episode features Slice CGO, Philippe Lafreniere, Slice CEO, Tim Attia and Rob Galbraith, Director of Innovation at the AF Group.",
  },
  {
    title:
      "A Conversation Around The Customer Experience And More At DigIn Analytics & AI 2019",
    itunes:
      "https://podcasts.apple.com/us/podcast/freshly-sliced/id1467798530#episodeGuid=tag%3Asoundcloud%2C2010%3Atracks%2F715031845",
    spotify:
      "https://open.spotify.com/episode/1yxh97RYgOnkadtpRoqWv4?si=uEhmeW-kQLGzn18qEF4qIw",
    soundcloud:
      "https://soundcloud.com/freshly_sliced/a-conversation-around-data-partnerships-and-more",
    duration: "17 min.",
    details:
      "Lead Data Scientist at Slice, Andy Clapson, and Duuo’s Claims and Compliance Manager, Luc Lamadeleine give you the chance to sit in on a conversation around new product innovation as they are fresh from a fireside chat at DigIn Analytics & AI 2019 in Las Vegas. The two share thoughts on the industry event, collaboration, and more!",
  },
  {
    title: "The Women Designing Slice",
    itunes:
      "https://podcasts.apple.com/us/podcast/the-women-designing-slice/id1467798530?i=1000454691727",
    spotify: "https://open.spotify.com/show/2BM8GAQFxDPfIhscdRIIHw",
    soundcloud:
      "https://soundcloud.com/freshly_sliced/the-women-designing-slice",
    duration: "23 min.",
    details:
      "On this episode we feature the women behind the Slice marketing team. VP of Marketing, Emily Kosick, Graphic Designer/Creative Director, Shailyn Bruining and Creative Director, Holly Kurtz. These three are responsible for the voice and aesthetic of the Slice brand and dive into their process on collaboration and recent accomplishments. Moderated by our very own Head of SMB, Jocelyn Getson.",
  },
  {
    title:
      "How Machine Learning & Small Data Shape On-Demand Insurance Customer Experiences",
    itunes:
      "https://podcasts.apple.com/us/podcast/how-machine-learning-small-data-shape-on-demand-insurance/id1467798530?i=1000448475571",
    spotify:
      "https://open.spotify.com/episode/7x4grf2P49m92tCNfPeP47?si=OfcBR8r8Svmtxb4c1H6QkA",
    soundcloud:
      "https://soundcloud.com/freshly_sliced/how-machine-learning-small-data-shape-on-demand-insurance-customer-experiences-w-andy-clapson",
    duration: "25 min.",
    details:
      "On this episode of Freshly Sliced, we chat with our very own Lead Data Scientist, Andy Clapson. Andy recently made the list for the Analytics Insight® “40 Under 40 Innovators”, and contributes his knowledge in this episode around small data, insurance, machine learning and the future of leveraging data. Enjoy!",
  },
  {
    title:
      "Safety and Protection in Homeshare With Beth Santos and Evelyn Badia",
    itunes:
      "https://podcasts.apple.com/us/podcast/safety-protection-in-homeshare-beth-santos-evelyn-badia/id1467798530?i=1000446227161",
    spotify:
      "https://open.spotify.com/episode/5Tk0dCrJcuIJNjOC2tnxmt?si=kE9fokpYRByESedbe_SP2w",
    soundcloud:
      "https://soundcloud.com/freshly_sliced/safety-and-protection-in-homeshare-with-beth-santos-and-evelyn-badia",
    duration: "28 min.",
    details:
      "On this episode we are joined by Beth Santos, Founder & CEO Wanderful, and Evelyn Baida, Airbnb Superhost and Founder of The Hosting Journey. We have a discussion around safety and protection in homeshare and how women are staking their claim in the sharing economy.",
  },
  {
    title:
      "Is Insurtech the Key to the Success of Tomorrow’s Digital Ecosystems?",
    itunes:
      "https://podcasts.apple.com/us/podcast/is-insurtech-key-to-success-tomorrows-digital-ecosystems/id1467798530?i=1000442505607",
    spotify:
      "https://open.spotify.com/episode/3Pn5ngV12OgEPBSGVYc9Ki?si=FgBjcPZSSPOxR3-FyX5x0g",
    soundcloud:
      "https://soundcloud.com/freshly_sliced/is-insurtech-the-key-to-the-success-of-tomorrows-digital-ecosystems",
    duration: "25 min.",
    details:
      "On this episode we cover innovation hurdles and enablers, and value to digital ecosystems. We are joined by Peter Primdahl, VP, Emerging Business Models @ The Co-operators Group, and Mike Fitzgerald, Senior Analyst @ Celent.",
  },
  {
    title:
      "Is On-Demand Cyber Insurance the Glue Holding Tomorrow’s Digital Ecosystems Together?",
    itunes:
      "https://podcasts.apple.com/us/podcast/is-on-demand-cyber-insurance-glue-holding-tomorrows/id1467798530?i=1000441072137",
    spotify:
      "https://open.spotify.com/episode/7FuTLq24kGwbph1xnxiXzz?si=hxrmz3GvRFWS0R5Wt-4jow",
    soundcloud:
      "https://soundcloud.com/freshly_sliced/is-on-demand-cyber-insurance-the-glue-holding-tomorrows-digital-ecosystems-together",
    duration: "28 min.",
    details:
      "On this episode we are joined by John Coletti, Chief Underwriting Officer @ AXA XL, and Slice’s own Head of Cyber, Jocelyn Getson. We discuss the landscape of cyber insurance and it’s roll / influence in digital ecosystems.",
  },
  {
    title: "The Value of Integrated Insurance into Digital Ecosystems",
    itunes:
      "https://podcasts.apple.com/us/podcast/the-value-of-integrated-insurance-into-digital-ecosystems/id1467798530?i=1000441072138",
    spotify:
      "https://open.spotify.com/episode/4UUErodgoCR7fSe8HL51Yp?si=Aup25D29QmKeR-1-m73RqQ",
    soundcloud:
      "https://soundcloud.com/freshly_sliced/the-value-of-integrated-insurance-into-digital-ecosystems",
    duration: "35 min.",
    details:
      "Welcome to Freshly Sliced! A podcast by Slice Labs. For our first episode, we are joined by Jennifer Overhulse, owner of St. Nick Media Services, and Dr. Robin Kiera of Digital Scouting. Episode hosted by Slice Chief Growth Office, Philippe Lafreniere.",
  },
]

export const guestPodcasts = [
  {
    link: "https://podcasts.apple.com/us/podcast/strategies-for-human-connection-in-a-digital-world/id1554307763?i=1000534864956",
    title: "Strategies for Human Connection in a Digital World • Jeremy Shere",
    img: engageYourTribe,
    duration: "10 min.",
    details:
      "Jeremy Shere, founder & CEO of Tribal Knowledge Podcasting, talks with Emily Mertz, VP of Marketing at Slice Labs about connecting with prospects and having genuine conversations that can lead to more business.",
  },
  {
    link: "https://youtu.be/Dvl5jZBHyeA",
    title: "OneTake with InsureTech Denver • Marc Wheeler",
    img: insuretechDenver,
    duration: "25 min.",
    details:
      "Slice CEO, Tim Attia joins Marc Wheeler to chat about the global insurance scene and everything insurtech.",
  },
  {
    link: "https://nexxworks.com/blog/webinar-the-future-trends-for-insurance",
    title:
      "The Future Trends for Insurance • Moderated by Philipp Kristian Diekhöner",
    img: futureTrends,
    duration: "59 min.",
    details: "Slice Panelist: Philippe Lafreniere",
  },
  {
    link: "https://youdontknowschmitt.podbean.com/e/emily-mertz-%e2%80%93-making-the-most-of-every-moment/",
    title: "Making the Most of Every Moment • Liv Schmidt",
    img: livLouder,
    duration: "40 min.",
    details:
      "With Slice’s focus on improving the insurance experience with technology, Emily Mertz, VP of Marketing at Slice, talks with Liv about how companies promote their innovation within the market.",
  },
  {
    link: "https://www.fnoinsuretech.com/podcast/episode/44d099aa/ep-87-slice-labs-ceo-and-co-founder-tim-attia",
    title: "FNO: InsureTech • Rob Beller and Lee Boyd",
    img: fnoInsuretech,
    duration: "49 min.",
    details: "EP 87 - Slice Labs CEO & Co-Founder, Tim Attia",
  },
  {
    link: "https://asiainsurtechpodcast.com/ep-71-tim-attia-ceo-slice-labs-insurance-being-part-of-other-buying-experiences/",
    title: "Asia InsurTech Podcast • Michael Waitze",
    img: asiaInsuretech,
    duration: "44 min.",
    details:
      "EP 71 - Tim Attia - CEO Slice Labs - Insurance Being Part of Other Buying Experiences",
  },
  {
    link: "https://techblogwriter.co.uk/slice-labs/",
    title: "The Tech Blog Writer Podcast • Neil Hughes",
    img: techBlogWriter,
    duration: "28 min.",
    details:
      "855: Slice Mind – AI, Machine Learning and Behavioral Psychology. Slice Labs’ CTO, Stu Baserman joins me on my daily tech podcast to dive deeper into the tech behind Slice Mind and how the insight engine works.",
  },
  {
    link: "https://iireporter.com/axa-xls-on-demand-cyber-insurance-product-john-coletti-cuo/",
    title: "IIREPORTER’S PODCAST with Anthony R. O’Donnell",
    img: iir,
    duration: "21 min.",
    details:
      "Using Slice ICS, the insurer took a minimum viable product approach to rapidly offering a product that uses data and automation to reach an underserved market.",
  },
  {
    link: "https://ii.11fs.com/38",
    title: "Insurtech Insider Podcast by 11:FS - 38. News: a Slice of AI",
    img: insuretch,
    duration: "51 min.",
    details:
      "Kicking off the show, the panel talks about Insurtech Slice Lab unveiling a new AI-based tool to predict insurance risk.",
  },
  {
    link: "https://ii.11fs.com/29",
    title: "Insurtech Insider Podcast by 11:FS - 29: Partnerships",
    img: insuretch,
    duration: "51 min.",
    details:
      "A discussion on the types of partnerships in the industry, the benefits they bring and what role (if any) regulators should play.",
  },
]

export const pressReleases = [
  {
    year: 2024,
    articles: [
      {
        title: "Slice Labs Expands Mid-Market Offering for Contractors General Liability in the Excess & Surplus Market",
        link: "https://www.businesswire.com/news/home/20241015761136/en/Slice-Labs-Expands-Mid-Market-Offering-for-Contractors-General-Liability-in-the-Excess-Surplus-Market",
        timestamp: "October 15, 2024"
      },
      {
        title: "Slice Expands to 47 States + D.C. with PHLY AM Best A++ Rated Contractors General Liability Insurance",
        link: "https://www.businesswire.com/news/home/20240409861518/en/Slice-Expands-to-47-States-D.C.-with-PHLY-AM-Best-A-Rated-Contractors-General-Liability-Insurance",
        timestamp: "April 9, 2024"
      },
    ]
  },
  {
    year: 2023,
    articles: [
      {
        title: "Slice Now in 39 States with PHLY AM Best A++ Rated Contractors General Liability Insurance",
        link: "https://www.businesswire.com/news/home/20231127919462/en/Slice-Now-in-39-States-with-PHLY-AM-Best-A-Rated-Contractors-General-Liability-Insurance",
        timestamp: "November 27, 2023"
      },
      {
        title: "Slice Adds 9 States to AERO’s Workers’ Comp Bringing Covered States to 21",
        link: "https://www.businesswire.com/news/home/20230808944848/en/Slice-Adds-9-States-to-AERO%E2%80%99s-Workers%E2%80%99-Comp-Bringing-Covered-States-to-21",
        timestamp: "August 8, 2023"
      },
      {
        title: "Slice Expands to 9 Additional States with AM Best A++ Small Business Insurance",
        link: "https://www.businesswire.com/news/home/20230724948615/en/Slice-Expands-to-9-Additional-States-with-AM-Best-A-Small-Business-Insurance",
        timestamp: "July 24, 2023"
      },
      {
        title: "Slice Brings Workers’ Comp to Distribution Partners",
        link: "https://www.businesswire.com/news/home/20230719641446/en/Slice-Brings-Workers%E2%80%99-Comp-to-Distribution-Partners",
        timestamp: "July 19, 2023"
      },
      {
        title: "Slice Adds First Connect for Small Business Insurance Distribution",
        link: "https://www.businesswire.com/news/home/20230712324347/en/Slice-Adds-First-Connect-for-Small-Business-Insurance-Distribution",
        timestamp: "July 12, 2023"
      },
      {
        title: "Security Underwriting Managers Signs with Slice for Small Business Insurance",
        link: "https://www.businesswire.com/news/home/20230502005388/en/Security-Underwriting-Managers-Signs-with-Slice-for-Small-Business-Insurance",
        timestamp: "May 2, 2023"
      },
      {
        title: "Slice Launches New On-Demand Small Business Product for Specialty Market",
        link: "https://www.businesswire.com/news/home/20230425005950/en/Slice-Launches-New-On-Demand-Small-Business-Product-for-Specialty-Market",
        timestamp: "April 25, 2023"
      },
      {
        title: "Slice Onboards 4 New Distribution Partners for Small Business Insurance",
        link: "https://www.businesswire.com/news/home/20230322005580/en/Slice-Onboards-4-New-Distribution-Partners-for-Small-Business-Insurance",
        timestamp: "March 22, 2023"
      }
    ]
  },
  {
    year: 2022,
    articles: [
      {
        title: "Access One80 Now Distributing Slice’s General Liability for Small Businesses",
        link: "https://www.businesswire.com/news/home/20221018005096/en/Access-One80-Now-Distributing-Slice%E2%80%99s-General-Liability-for-Small-Businesses",
        timestamp: "October 19, 2022"
      },
      {
        title: "Momentum for Slice in Small Business Insurance – Additional States & Coverage Options Added",
        link: "https://1668259.fs1.hubspotusercontent-na1.net/hubfs/1668259/Momentum%20for%20Slice%20in%20Small%20Business%20Insurance.pdf",
        timestamp: "October 17, 2022"
      },
      {
        title: "London Underwriters Launches with Slice to Protect Small Businesses",
        link: "https://www.businesswire.com/news/home/20220614005135/en/London-Underwriters-Launches-With-Slice-to-Protect-Small-Businesses",
        timestamp: "June 14, 2022"
      },
      {
        title: "Slice and Eastern Underwriting Managers Partner to Protect Small Businesses",
        link: "https://www.businesswire.com/news/home/20220525005126/en/Slice-and-Eastern-Underwriting-Managers-Partner-to-Protect-Small-Businesses",
        timestamp: "May 25, 2022"
      },
      {
        title: "Slice Adds Remodeling Contractors to Small Business General Liability Insurance Coverage",
        link: "https://www.businesswire.com/news/home/20220331005131/en/Slice-Adds-Remodeling-Contractors-to-Small-Business-General-Liability-Insurance-Coverage",
        timestamp: "March 31, 2022"
      },
      {
        title:
          "Slice Announces Release of Duuo Small Business Insurance",
        link: "https://www.businesswire.com/news/home/20220316005046/en/Slice-Announces-Release-of-Duuo-Small-Business-Insurance",
        timestamp: "March 16, 2022",
      },
      {
        title:
          "Slice Strengthens Commitment to Protecting Small Businesses With the Launch of AERO’s Workers’ Compensation Insurance",
        link: "https://www.businesswire.com/news/home/20220308005294/en/Slice-Strengthens-Commitment-to-Protecting-Small-Businesses-With-the-Launch-of-AERO%E2%80%99s-Workers%E2%80%99-Compensation-Insurance",
        timestamp: "March 08, 2022",
      },
      {
        title: "Slice Lands in Harvard Business School Research Publication",
        link: "https://www.businesswire.com/news/home/52583434/en/",
        timestamp: "February 23, 2022",
      },
    ],
  },
  {
    year: 2021,
    articles: [
      {
        title:
          "Slice Celebrates State and Class Expansion of Contractors General Liability Insurance for Small Businesses",
        link: "https://www.businesswire.com/news/home/52553098/en",
        timestamp: "December 20, 2021",
      },

      {
        title:
          "Appalachian Underwriters Launches Digital Platform for Small Business Insurance Via Slice",
        link: "https://www.businesswire.com/news/home/20210628005016/en/",
        timestamp: "June 28, 2021",
      },
      {
        title: "Tim Attia of Slice to Speak at Morgan Stanley Conference",
        link: "https://www.businesswire.com/news/home/20210608005115/en/Tim-Attia-of-Slice-to-Speak-at",
        timestamp: "June 09, 2021",
      },
      {
        title: "Slice Launches Automated Claims Settlement",
        link: "https://www.businesswire.com/news/home/52372537/en",
        timestamp: "February 3, 2021",
      },
    ],
  },
  {
    year: 2020,
    articles: [
      {
        title:
          "Appalachian Underwriters Partners with Slice Labs to Build and Distribute Digital On-Demand Insurance",
        link: "https://www.businesswire.com/news/home/52339726/en",
        timestamp: "December 01, 2020",
      },
      {
        title:
          "Slice Labs and AXIS Insurance Collaborate to Bring Home Based Business Insurance to Small Businesses",
        link: "https://www.businesswire.com/news/home/52313060/en",
        timestamp: "October 27, 2020",
      },
      {
        title:
          "Slice Labs & AXA XL Offer Lenovo Small Business Users On-Demand Cyber Insurance",
        link: "https://www.businesswire.com/news/home/20200423005081/en",
        timestamp: "April 23, 2020",
      },
      {
        title: "Slice Announces Four Slice Mind Services",
        link: "https://www.businesswire.com/news/home/52164199/en",
        timestamp: "January 29, 2020",
      },
      {
        title:
          "Slice and SOMPO Release First On-Demand Insurance Product in Asia on ICS",
        link: "https://www.businesswire.com/news/home/52159188/en",
        timestamp: "January 16, 2020",
      },
    ],
  },
  {
    year: 2019,
    articles: [
      {
        title:
          "Slice Joins CTA Small Business Council Board to Advance Awareness of On-Demand Insurance",
        link: "https://www.businesswire.com/news/home/20191029005052/en/Slice-Joins-CTA-Small-Business-Council-Board",
        timestamp: "October 29, 2019",
      },
      {
        title:
          "New digital brand of Top 5 Canadian insurer introduces Event and Rent-My-Stuff on-demand insurance.",
        link: "https://www.businesswire.com/news/home/20191003005066/en/Slice-Announces-Duuo-Release-New-On-Demand-Insurance",
        timestamp: "October 3, 2019",
      },
      {
        title:
          "Slice ICS platform now used by top insurers in the U.S., Canada, UK, and Asia Pacific",
        link: "https://www.businesswire.com/news/home/20190814005256/en/Slice-SOMPO-Announce-ICS-Proof-of-Value-Contract-Bringing",
        timestamp: "August 14, 2019",
      },
      {
        title: "Slice Labs Launches Slice Mind",
        link: "https://www.businesswire.com/news/home/20190328005128/en/Slice-Labs-Launches-Slice-Mind",
        timestamp: "March 28, 2019",
      },
      {
        title:
          "Nationwide Partners with Slice Labs to Develop On-Demand Auto Insurance For Rideshare Drivers",
        link: "https://www.nationwide.com/personal/about-us/newsroom/press-release?title=031919-slice",
        timestamp: "March 19, 2019",
      },
    ],
  },
  {
    year: 2018,
    articles: [
      {
        title:
          "Slice Launch Studio Opens to Insurers and Tech Companies to Create Digital Insurance Products",
        link: "https://www.businesswire.com/news/home/20181219005105/en/Slice-Launch-Studio-Opens-Insurers-Tech-Companies",
        timestamp: "December 19, 2018",
      },
      {
        title: "Slice Insurance Cloud Services Now Available in the UK & EU",
        link: "https://www.businesswire.com/news/home/20181213005058/en/Slice-Insurance-Cloud-Services-UK-EU",
        timestamp: "December 13, 2018",
      },
      {
        title:
          "AXA XL Launches Cyber Insurance for Small & Mid-Size Businesses on Slice ICS",
        link: "https://www.businesswire.com/news/home/20181030005877/en/AXA-XL-Launches-Cyber-Insurance-Small-Mid-Size",
        timestamp: "October 30, 2018",
      },
      {
        title:
          "Slice Labs Raises $20M in Extended Series A to Globalize its On-Demand Insurance Cloud Platform",
        link: "https://www.businesswire.com/news/home/20180926005145/en/Slice-Labs-Raises-20M-Extended-Series-Globalize",
        timestamp: "September 26, 2018",
      },
      {
        title:
          "Slice ICS Customer Announces Go-Live of First Digital Insurance Product",
        link: "https://www.businesswire.com/news/home/20180918005221/en/Slice-ICS-Customer-Announces-Go-Live-Digital-Insurance",
        timestamp: "September 18, 2018",
      },
      {
        title:
          "Slice Labs Strengthens Insurance Cloud Focus with Veteran Industry Executive Hire",
        link: "https://www.businesswire.com/news/home/20180815005017/en/Slice-Labs-Strengthens-Insurance-Cloud-Focus-Veteran",
        timestamp: "August 15, 2018",
      },
      {
        title:
          "XL Catlin to Build World’s First On-Demand Cyber Insurance Solution with Slice ICS",
        link: "https://www.businesswire.com/news/home/20180725005046/en/XL-Catlin-Build-World%E2%80%99s-On-Demand-Cyber-Insurance",
        timestamp: "July 25, 2018",
      },
      {
        title:
          "Slice Labs Joins Large Insurers in Winning Celent Model Insurer Award",
        link: "https://www.businesswire.com/news/home/20180424005250/en/Slice-Labs-Joins-Large-Insurers-Winning-Celent",
        timestamp: "April 24, 2018",
      },
      {
        title:
          "Legal & General Announces New Partnership with Technology Partner Slice Labs to Roll Out ‘On-Demand’ Homeshare Insurance",
        link: "https://www.businesswire.com/news/home/20180220005285/en/Legal-General-Announces-New-Partnership-Technology-Partner",
        timestamp: "February 20, 2018",
      },
      {
        title: "Slice Launches Its On-Demand Digital Insurer-as-a-Service",
        link: "https://www.businesswire.com/news/home/20180125005221/en/Slice-Launches-On-Demand-Digital-Insurer-as-a-Service",
        timestamp: "January 25, 2018",
      },
      {
        title:
          "Slice Digital Insurance For Short-Term Rentals Is a Big Win In Time For The Big Game",
        link: "https://www.businesswire.com/news/home/20180110005018/en/Slice-Digital-Insurance-Short-Term-Rentals-Big-Win",
        timestamp: "January 10, 2018",
      },
    ],
  },
  {
    year: 2017,
    articles: [
      {
        title:
          "Slice Raises $11.6M Series A to Scale its Digital Insurance Platform",
        link: "https://www.businesswire.com/news/home/20171005005075/en/Slice-Raises-11.6M-Series-Scale-Digital-Insurance",
        timestamp: "October 05, 2017",
      },
      {
        title: "Slice Releases Rideshare App to Select Drivers",
        link: "https://www.businesswire.com/news/home/20170329005305/en/Slice-Releases-Rideshare-App-Select-Drivers",
        timestamp: "March 29, 2017",
      },
      {
        title:
          "Slice and Digital Partners Win the Insurance Technology Association’s Bridge Award",
        link: "https://www.businesswire.com/news/home/20170313005371/en/Slice-Digital-Partners-Win-Insurance-Technology-Association%E2%80%99s",
        timestamp: "March 13, 2017",
      },
      {
        title:
          "Slice Expands On-Demand Homeshare Insurance Product to Five Additional States",
        link: "https://www.businesswire.com/news/home/20170301005134/en/Slice-Expands-On-Demand-Homeshare-Insurance-Product-Additional",
        timestamp: "March 01, 2017",
      },
    ],
  },
  {
    year: 2016,
    articles: [
      {
        title: "Slice Launches the First On-Demand Insurance Product in the US",
        link: "https://www.businesswire.com/news/home/20161005005030/en/Slice-Launches-On-Demand-Insurance-Product",
        timestamp: "October 05, 2016",
      },
      {
        title:
          "Slice Labs Announces Additional Funding from Munich Re / HSB Ventures",
        link: "https://www.businesswire.com/news/home/20161004005386/en/Slice-Labs-Announces-Additional-Funding-Munich-HSB",
        timestamp: "October 04, 2016",
      },
      {
        title:
          "Slice Labs Partners with Munich Re to Deliver On-Demand Insurance Products Globally",
        link: "https://www.businesswire.com/news/home/20160711005647/en/Slice-Labs-Partners-Munich-Deliver-On-Demand-Insurance",
        timestamp: "July 11, 2016",
      },
      {
        title: "Slice Labs Hires Dawid Glawdzin from Google",
        link: "https://www.businesswire.com/news/home/20160616005306/en/Slice-Labs-Hires-Dawid-Glawdzin-Google",
        timestamp: "June 16, 2016",
      },
      {
        title:
          "Slice Labs Adds Insurance Veteran Tom Hutton to Board of Directors",
        link: "https://www.businesswire.com/news/home/20160503005420/en/",
        timestamp: "May 03, 2016",
      },
      {
        title:
          "Slice Labs Raises $3.9M Seed Round Led by Horizons Ventures and XL Innovate",
        link: "https://www.businesswire.com/news/home/20160329005191/en/Slice-Labs-Raises-3.9M-Seed-Led-Horizons",
        timestamp: "March 29, 2016",
      },
    ],
  },
]
