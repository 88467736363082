import React, { useState } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PressMediaMeta } from "../data/seo-meta"
import {
  freshlySlicedPodcasts,
  guestPodcasts,
  pressReleases,
} from "../data/press-media"

import freshlySlicedLogo from "../images/press-media/freshly_sliced.svg"
import itunesLogo from "../images/press-media/itunes.png"
import spotifyLogo from "../images/press-media/spotify.png"
import soundcloudLogo from "../images/press-media/soundcloud.png"
import rightArrow from "../images/common/right_arrow.svg"
import angleCaret from "../images/common/angle-down-sm_black.svg"
import angleCaretRed from "../images/common/angle-down-sm_red.svg"

/** Supporting components */

const SlicePodcast = ({
  title,
  duration,
  details,
  itunes,
  spotify,
  soundcloud,
}) => {
  const [readMore, setReadmore] = useState(false)
  const detailsClass = clsx("p2 overflow-hidden", {
    "max-h-auto": readMore,
    "max-h-36": !readMore,
  })
  const readMoreClass = clsx("p2 font-semibold", { hidden: readMore })
  const toggleReadMore = () => {
    setReadmore(true)
  }
  return (
    <div className="border-t-gray-300 border-t pt-4 pb-16">
      <h3 className="p1 font-semibold">{title}</h3>
      <span className="p2 mb-4 block">{duration}</span>
      <p className={detailsClass}>{details}</p>
      <button className={readMoreClass} onClick={toggleReadMore}>
        ...more details
      </button>
      <div className="flex justify-end items-center p2 font-semibold pt-8">
        Listen:
        <a
          className="ml-4"
          href={itunes}
          rel="nofollow noreferrer"
          target="_blank"
        >
          <img src={itunesLogo} alt="Listen to this podcast on iTunes" />
        </a>
        <a
          className="ml-4"
          href={spotify}
          rel="nofollow noreferrer"
          target="_blank"
        >
          <img src={spotifyLogo} alt="Listen to this podcast on Spotify" />
        </a>
        <a
          className="ml-4"
          href={soundcloud}
          rel="nofollow noreferrer"
          target="_blank"
        >
          <img
            src={soundcloudLogo}
            alt="Listen to this podcast on Soundcloud"
          />
        </a>
      </div>
    </div>
  )
}

SlicePodcast.propTypes = {
  title: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  itunes: PropTypes.string.isRequired,
  spotify: PropTypes.string.isRequired,
  soundcloud: PropTypes.string.isRequired,
}

const GuestPodcast = ({ image, title, duration, details, link }) => {
  const [readMore, setReadMore] = useState(false)
  const detailsClass = clsx("p2 overflow-hidden", {
    "max-h-auto": readMore,
    "max-h-36": !readMore,
  })
  const readMoreClass = clsx("p2 font-semibold", { hidden: readMore })
  const toggleReadMore = () => {
    setReadMore(true)
  }
  return (
    <div className="border-t-gray-300 border-t pt-4 pb-16">
      <div className="flex mb-12">
        <img
          className="hidden md:block w-40 mr-12"
          src={image}
          alt="Guest podcast logo"
        />
        <div>
          <h3 className="p1 font-semibold">{title}</h3>
          <span className="p2 mb-4 block">{duration}</span>
        </div>
      </div>
      <p className={detailsClass}>{details}</p>
      <button className={readMoreClass} onClick={toggleReadMore}>
        ...more details
      </button>
      <div className="text-right pt-8">
        <a
          className="p2 font-semibold"
          href={link}
          rel="noreferrer nofollow"
          target="_blank"
        >
          Listen here
          <img
            className="inline-block w-8 -translate-y-1 ml-2"
            src={rightArrow}
            alt="right arrow"
          />
        </a>
      </div>
    </div>
  )
}

GuestPodcast.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

const ArticleGroup = ({ year, articles }) => {
  const [expanded, setExpanded] = useState(false)
  const groupClass = clsx(
    "mt-6 pt-2 pb-8 border-t border-t-gray-300 overflow-hidden",
    {
      "max-h-auto": expanded,
      "max-h-24": !expanded,
    }
  )
  const caretClass = clsx("w-10 mr-4 transition-all", {
    "rotate-0": expanded,
    "-rotate-90": !expanded,
  })
  const toggle = () => {
    setExpanded(!expanded)
  }
  return (
    <div className={groupClass}>
      <h3
        className="p1 font-semibold flex mb-12 cursor-pointer"
        onClick={toggle}
      >
        <img className={caretClass} src={angleCaret} alt="angle caret" />
        {year}
      </h3>
      {articles.map((article) => (
        <div className="mb-12" key={article.timestamp}>
          <a
            className="p1 font-semibold block"
            href={article.link}
            rel="noreferrer nofollow"
            target="_blank"
          >
            {article.title}
          </a>
          <span className="p2 block">{article.timestamp}</span>
        </div>
      ))}
    </div>
  )
}

ArticleGroup.propTypes = {
  year: PropTypes.number.isRequired,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
    })
  ),
}

const ShowMoreButton = ({ expanded, onClick }) => {
  const caretClass = clsx("w-10 mr-2 transition-all", {
    "-rotate-90": !expanded,
    "-rotate-180": expanded,
  })
  return (
    <button
      className="cta border-b-[3px] border-b-[#D91451] pb-2 flex items-center"
      onClick={onClick}
    >
      <img className={caretClass} src={angleCaretRed} alt="angle caret" />
      Show {expanded ? "less" : "more"}
    </button>
  )
}

ShowMoreButton.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

/** Main page component */

const PressMediaPage = () => {
  const defaultLimit = 3
  const [slicePodcastLimit, setSlicePodcastLimit] = useState(defaultLimit)
  const [guestPodcastLimit, setGuestPodcastLimit] = useState(defaultLimit)
  const [pressReleaseLimit, setPressReleaseLimit] = useState(defaultLimit)
  const toggleSlicePodcasts = () => {
    setSlicePodcastLimit(
      slicePodcastLimit === defaultLimit
        ? freshlySlicedPodcasts.length
        : defaultLimit
    )
  }
  const toggleGuestPodcasts = () => {
    setGuestPodcastLimit(
      guestPodcastLimit === defaultLimit ? guestPodcasts.length : defaultLimit
    )
  }
  const togglePressReleases = () => {
    setPressReleaseLimit(
      pressReleaseLimit === defaultLimit ? pressReleases.length : defaultLimit
    )
  }
  return (
    <Layout>
      <SEO
        description={PressMediaMeta.description}
        path={PressMediaMeta.path}
        title={PressMediaMeta.title}
      />

      <div className="container py-20 md:py-24">
        <h1 className="h1 mb-20 max-w-[43rem]">Our Story</h1>

        <div className="grid md:grid-cols-2 md:gap-x-40">
          <div className="pb-32">
            <h2 className="h2">Press Releases</h2>
            <p className="p1 pt-4 pb-12">
              Want to write about us? Reach out to us at&nbsp;
              <a
                href="mailto:media@sliceinsurance.com"
                rel="noreferrer nofollow"
                target="_blank"
              >
                media@sliceinsurance.com
              </a>
              .
            </p>
            {pressReleases.slice(0, pressReleaseLimit).map((release) => (
              <ArticleGroup
                year={release.year}
                articles={release.articles}
                key={release.year}
              />
            ))}
            <ShowMoreButton
              expanded={pressReleaseLimit !== defaultLimit}
              onClick={togglePressReleases}
            />
          </div>
          <div>
            <h2 className="h2">Podcasts</h2>
            <div className="flex flex-wrap pb-12">
              <img
                className="w-60 h-60 -ml-8"
                src={freshlySlicedLogo}
                alt="Freshly Sliced Podcast Logo"
              />
              <p className="p1 pt-4 basis-full md:basis-2/3">
                <strong>Freshly Sliced</strong> features the latest trends, and
                thought leaders from the insurtech and fintech world all sharing
                insights on the future of the insurance industry.
              </p>
            </div>
            {freshlySlicedPodcasts
              .slice(0, slicePodcastLimit)
              .map((podcast) => (
                <SlicePodcast
                  spotify={podcast.spotify}
                  title={podcast.title}
                  duration={podcast.duration}
                  itunes={podcast.itunes}
                  details={podcast.details}
                  soundcloud={podcast.soundcloud}
                  key={podcast.title}
                />
              ))}
            <ShowMoreButton
              expanded={slicePodcastLimit !== defaultLimit}
              onClick={toggleSlicePodcasts}
            />

            <h2 className="h2 pt-24 mb-12">
              Slice guest appearances and honorable mentions.
            </h2>
            {guestPodcasts.slice(0, guestPodcastLimit).map((podcast) => (
              <GuestPodcast
                duration={podcast.duration}
                image={podcast.img}
                details={podcast.details}
                title={podcast.title}
                link={podcast.link}
                key={podcast.link}
              />
            ))}
            <ShowMoreButton
              expanded={guestPodcastLimit !== defaultLimit}
              onClick={toggleGuestPodcasts}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PressMediaPage
